<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">标题：</span>
					<el-input v-model="searchtitle" placeholder="请输入标题" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
				</div>
			</div>
			<div class="box_cont">
				
				<div style="height:70vh;overflow-y: auto;width: 100%;">
					<table class="mytable">
						<thead>
							<tr>
							  <th style="width: 32%;color: #777;background-color: rgb(242, 242, 242);">合并后的提案名</th>
							  <!-- <th style="width: 15%;">合并后的提案编号</th> -->
							  <th style="width: 32%;color: #777;background-color: rgb(242, 242, 242);">已合并的提案</th>
							  <!-- <th style="width: 15%;">已合并的提案编号</th> -->
							  <th style="width: 6%;color: #777;background-color: rgb(242, 242, 242);">操作</th>
							</tr>
						</thead>
						<template v-for="(item,index) in list" style="margin-bottom:10px;">
							<tr>
							  <td style="width: 32%;" :rowspan="item.proposal.length">{{item.name}}</td>
							  
							  <!-- <td style="width: 15%;" :rowspan="item.proposal.length">{{item.code}}</td> -->
							  
							  <td style="width:100%;display: flex;align-items: center;justify-content: space-between;padding-left:20px;padding-right:20px;">{{item.proposal[0].name}}<el-button type="success" size="mini" style="margin-left:15px;" @click="opendetail(item.proposal[0].id)">详情</el-button></td>
							  
							  <!-- <td style="width: 15%;">{{item.proposal[0].code}}</td> -->
							  
							  <td style="width: 6%;border-right:1px solid #eee;" :rowspan="item.proposal.length">
								    <el-button type="primary" size="mini" @click="opendetail(item.id)">详情</el-button>
							  </td>
							</tr>
							
							<tr v-for="(item2,index2) in item.proposal" v-if="index2!=0" :class="index2+1==item.proposal.length?'lasttr':''">
							  <td style="width:100%;display: flex;align-items: center;justify-content: space-between;padding-left:20px;padding-right:20px;border-top:1px solid #eee;">{{item2.name}}<el-button type="success" size="mini" style="margin-left:15px;" @click="opendetail(item2.id)">详情</el-button></td>
							  <!-- <td style="width:15%;border-top:1px solid #eee;">{{item2.code}}</td> -->
							</tr>
						</template>
						<div class="tipsno" v-show="list.length<=0">
							暂无数据~
						</div>
					</table>
				</div>
				
				<div style="margin-top:20px;border-top:1px solid #eee;padding-top:10px;display: flex;justify-content: flex-end;">
					<el-pagination
					  background
					  layout="total,prev, pager, next"
					  :total="total"
					  @current-change="pagechange">
					</el-pagination>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">			
		</mydetail>
	</div>
</template>

<script>
	import mydetail from "@/components/detail_tc.vue";
	export default {
		components: {
			mydetail
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				list:[],
				searchtitle:'',
				page:1,
				total:0
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			
			search() {
				this.page = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/he_page_list',
					params: {
						title:this.searchtitle,
						page: this.page,
						pageSize: 10
					}
				}).then((res) => {
					this.list = res.list
					this.total = res.count
				})
			},
			opendetail(item) {
				this.detail_id = item
				this.showdetail = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
	.searchbox{
		padding:1.5rem;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
		border-radius: 10px;
		display:flex;
	}
	.searchitem{
		display: flex;
	    align-items: center;
	    justify-content: center;
	    width: fit-content;		
	}
	.searchitem span{
		width: 3rem;
	}
	.mytablebox{
		margin-top:10px;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
		border-radius: 10px;
		padding:1rem;
		height:730px;		
	}
	.mytable{
		width: 100%;
		
	}
	.mytable tr{
		
	}
	.mytable th{
		color: #909399;
		font-size: 15px;
		border-bottom:1px solid #eee;
		padding: 12px 0;
		width: 40%;
		border:1px solid #eee;
		
	}
	.mytable td{
		color: #606266;
		font-size: 14px;
		text-align: center;
		padding: 5px;
		width: 38%;
		border-left:1px solid #eee;
	}
	.lasttr{
		border-bottom: 1px solid #eee;
	}
	.tipsno{
		margin-top:40px;
		color:#999;
		text-align: center;
		font-size: 0.2rem;
	}
	thead tr th {
		position:sticky;
		top:0;
		background-color: #fff;
	}
	.el-pagination{
		display: flex;
	}
</style>